import { Grid2 } from '@mui/material';

export default function Ansprechpartner() {
    return (<Grid2 container > 
        <Grid2 size={12} display="flex" justifyContent="center" alignItems="center">
            <h3 style={{color: "rgb(255,230,0 )", textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black" ,fontSize: "x-large"}}>
                Unsere Ansprechpartner:
            </h3>
        </Grid2>
        <Grid2 size={{xs:12, md:3}} display="flex" justifyContent="center" alignItems="center">
            <span style={{color: "rgb(255,230,0 )", textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "x-large", fontWeight: "bold"}}>
                Kontakt Jugend:
            </span>
        </Grid2>
        <Grid2 size={{xs: 12, md:3}} display="flex" justifyContent="center" alignItems="center">
            <span style={{color: "rgb(255,230,0 )",textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "large"}}>
                Jugendleiter: Matteo Foschi
            </span>
        </Grid2>
        <Grid2 size={{xs:12, md:3}} display="flex" justifyContent="center" alignItems="center"> 
            <span style={{color: "rgb(255,230,0 )", textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "large"}}>
                    Email: jugend@djkdurlach.de 
            </span>
        </Grid2>
        <Grid2 size={{xs:12, md:3}} display="flex" justifyContent="center" alignItems="center">
            <span style={{color: "rgb(255,230,0 )", textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "large"}}>
                Tel: 0176-95452012
            </span>
        </Grid2>
        <Grid2 size={{xs:12, md:3}} display="flex" justifyContent="center" alignItems="center">
            <span style={{color: "rgb(255,230,0 )", textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "x-large", fontWeight: "bold"}}>
                Kontakt Senioren:
            </span>
        </Grid2>
        <Grid2 size={{xs: 12, md:3}} display="flex" justifyContent="center" alignItems="center">
            <span style={{color: "rgb(255,230,0 )",textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "large"}}>
                Seniorenleiter: Sven Lutz
            </span>
        </Grid2>
        <Grid2 size={{xs:12, md:3}} display="flex" justifyContent="center" alignItems="center">
            <span style={{color: "rgb(255,230,0 )", textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "large"}}>
                Email: aktivitaet@djkdurlach.de
            </span>
        </Grid2>
        <Grid2 size={{xs:12, md:3}} display="flex" justifyContent="center" alignItems="center">
            <span style={{color: "rgb(255,230,0 )",textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "large"}}>
                Tel: ---
            </span>
        </Grid2>
    </Grid2>)
}