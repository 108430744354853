import { Grid2 } from '@mui/material';
import SideNav from '../Komponenten/Sidenav';
import Header from '../Komponenten/Header';
import Ansprechpartner from '../Komponenten/Ansprechpartner';
import News from '../Komponenten/News';

export default function Startseite() {
    return (
        <>
            <SideNav />
            <Grid2 container padding={5} spacing={4}>
                <Grid2 size={12} display="flex" justifyContent="center" alignItems="center" >
                    <Header />
                </Grid2>
                <Grid2 size={12} display="flex" justifyContent="center" alignItems="center">
                    <span style={{color: "rgb(255,230,0 )", textAlign: 'center', textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "large"}}>
                        Herzlich Willkommen bei der DJK Durlach! <br /><br/>Schön, dass Sie den Weg zu uns gefunden haben! Wir sind ein traditionsreicher Fußballverein im Herzen von Durlach, der sich auf die Förderung junger Menschen spezialisiert hat. Mit Leidenschaft und Engagement bieten wir unseren Nachwuchsspielerninnen und Spieler die Möglichkeit, ihre fußballerischen Fähigkeiten zu entwickeln und gleichzeitig wichtige Werte wie Teamgeist, Fairness und Respekt zu erlernen.
                        <br /><br />
                        Unsere qualifizierten Trainer begleiten die jungen Talente auf ihrem sportlichen Weg und schaffen eine Umgebung, in der Freude am Spiel und persönliche Entwicklung im Vordergrund stehen.
                        <br /> <br/>
                        Kommen Sie vorbei und werden Sie Teil unserer Fußballgemeinschaft! Wir freuen uns darauf, Sie kennenzulernen.
                    </span>
                </Grid2>
                <Grid2 size={12} display="flex" justifyContent="center" alignItems="center">
                    <News />
                </Grid2>
                <Grid2 size={12} display="flex" justifyContent="center" alignItems="center">
                    <div
                        class="fussballde_widget"
                        data-id="153e32d7-f35e-4bbd-9dee-5df36f5b2f34"
                        data-type="club-matches">
                    </div>
                </Grid2>
                <Grid2 size={12}>
                    <Ansprechpartner />
                </Grid2>
            </Grid2>
        </>
    );
} 