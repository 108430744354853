import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Startseite from './Routes/Startseite';
import Trainingszeiten from './Routes/Trainingszeiten';
import BackgroundImage from "..\\src\\IMG_20230527_165621.jpg";


const router =  createBrowserRouter(
  createRoutesFromElements(
    <>
    <Route path='/Trainingszeiten' element={<Trainingszeiten/>}></Route>
    <Route path="/" element={<Startseite></Startseite>} />
    </>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',
        height: '100vh',
        overflowY: "scroll"
      }}>
    <RouterProvider router={router}></RouterProvider>
    </div>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
