import { Grid2, } from '@mui/material';
import SideNav from '../Komponenten/Sidenav';
import Header from '../Komponenten/Header';
import Ansprechpartner from '../Komponenten/Ansprechpartner';

export default function Trainingszeiten() {
    const mannschaften = [{team: "Aktivität", termin1: "Dienstag, 19:15-20:30", termin2: "Donnerstag, 19:15-20:30"},
        {team: "A-Jugend", termin1: "Montag 19.00-20.30", termin2: "Mittwoch 19.00-20.30"},
        {team: "B-Jugend", termin1: "Dienstag, 17.30-19.00 (Stupferich)", termin2: "Donnerstag, 17.30-19.00"},
        {team: "C-Jugend", termin1: "Dienstag, 17.30-19.00", termin2: "Freitag, 17.30-19.00"},
        {team: "D1-Jugend", termin1: "Dienstag, 17:30-19:00", termin2: "Donnerstag, 17.30-19:00"},
        {team: "D2-Jugend", termin1: "Mittwoch, 17:30-19:00", termin2: "Freitag, 17.30-19:00"},
        {team: "D3-Jugend", termin1: "Dienstag, 17:30-19:00", termin2: "Donnerstag, 17.30-19:00"},
        {team: "D4-Jugend", termin1: "Mittwoch, 17.30-19.00", termin2: "Freitag, 17.30-19.00"},
        {team: "E1-Jugend", termin1: "Montag 17.30-19.00", termin2: "Donnerstag, 17.30-19.00"},
        {team: "E2-Jugend", termin1: "Mittwoch, 17.00-18.30", termin2: "Freitag, 17.00-18.30"},
        {team: "E3-Jugend", termin1: "Mittwoch, 17.30-19.00", termin2: "Freitag, 17.30-19.00"},
        {team: "F-Jugend", termin1: "Dienstag, 16.45-18.00", termin2: "Freitag 16.30-18.00"},
        {team: "Bambini", termin1: "Freitag, 16.30-17.30", termin2: "---"},
    ]

    return (
        <>
        <SideNav />
        <Grid2 container padding={5} spacing={4}>
            <Grid2 size={12} display="flex" justifyContent="center" alignItems="center" >
                <Header />
            </Grid2>
            <Grid2 size={12} display="flex" justifyContent="center" alignItems="center">
                <h2 style={{color: "rgb(255,230,0 )", textAlign: 'center', textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black"}}>Trainingszeiten unserer Mannschaften</h2>
            </Grid2>
            <Grid2 size={12}>
                <Grid2 container spacing={2}> 
                    <Grid2 size={4} display="flex" justifyContent="center" alignItems="center">
                        <span style={{color: "rgb(255,230,0 )", textAlign: 'center', textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "large"}}>
                            Mannschaft
                        </span>
                    </Grid2>
                    <Grid2 size={4} display="flex" justifyContent="center" alignItems="center">
                        <span style={{color: "rgb(255,230,0 )", textAlign: 'center', textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "large"}}>
                            Termin 1
                        </span>
                    </Grid2>
                    <Grid2 size={4} display="flex" justifyContent="center" alignItems="center">
                        <span style={{color: "rgb(255,230,0 )", textAlign: 'center', textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "large"}}>
                            Termin 2
                        </span>
                    </Grid2>
                    {mannschaften.map((mannschaft) => {
                        return (
                            <>
                                <Grid2 size={4} display="flex" justifyContent="center" alignItems="center">
                                    <span style={{color: "rgb(255,230,0 )", textAlign: 'center', textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "large"}}>
                                        {mannschaft.team}
                                    </span>
                                </Grid2>
                                <Grid2 size={4} display="flex" justifyContent="center" alignItems="center">
                                    <span style={{color: "rgb(255,230,0 )", textAlign: 'center', textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", fontSize: "large"}}>
                                        {mannschaft.termin1}
                                    </span>
                                </Grid2>
                                <Grid2 size={4} display="flex" justifyContent="center" alignItems="center">
                                    <span style={{color: "rgb(255,230,0 )", textAlign: 'center', textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black"}}>
                                    {mannschaft.termin2}
                                    </span>
                                </Grid2>
                            </>);
                    })}
                </Grid2>
            </Grid2>
            <Grid2 size={12}>
                <Ansprechpartner />
            </Grid2>
        </Grid2>
        </>
    )
}